<template>
  <div class="form-box login-box" v-loading="loading">
    <div class="form-box-con">
      <div class="form-box-con-title">悟糖实时血糖管理平台</div>
      <div class="data-form">
        <el-form
          :model="dataForm"
          :rules="dataRule"
          ref="dataForm"
          @keyup.enter.native="dataFormSubmit()"
        >
          <el-form-item prop="account">
            <el-input v-model="dataForm.account" placeholder="请输入用户名/手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="dataForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <!-- <el-form-item prop="code" class="code-form-item">
            <el-input
              type="text"
              v-model="dataForm.code"
              placeholder="图形验证码"
              style="width: 68%"
            ></el-input>
            <img class="code-img" ref="img" src @click="getCaptcha()" />
          </el-form-item>-->
        </el-form>
        <div style="padding-top: 20px">
          <el-button class="submit-btn" type="primary" @click="getCaptcha">登录</el-button>
        </div>
      </div>
    </div>
    <div class="footer">©Copyright 2022 纳实医学科技 All Rights Reserved</div>
  </div>
</template>

<script>
import Qs from "qs";
var regPhone = /^1[34578]\d{9}$/,
  regPwd = /^[a-z|A-Z|0-9|\-|_]{4,20}$/;
import { getUUID } from "../../utils/index";
import MD5 from "js-md5";
export default {
  data() {
    /*var validatePhone = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入手机号'));
        } else if (!regPhone.test(value)) {
          callback(new Error('手机号格式不正确'));
        } else {
          callback();
        }
      }*/
    var validatePwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } /*else if (!regPwd.test(value)){
          callback(new Error('4-20位，字母、数字或_'));
        }*/ else {
        callback();
      }
    };
    return {
      usernameText: "", //用户名文本
      passwordText: "", //记住密码文本
      dataForm: {
        account: "",
        password: ""
        //codeImg: ''
      },
      dataRule: {
        account: [
          { required: true, message: "请输入用户名/手机号", trigger: "blur" }
        ],
        password: [{ required: true, validator: validatePwd, trigger: "blur" }],
        code: [{ required: true, message: "请输入图形验证码", trigger: "blur" }]
      },
      userInfo: {},
      loading: false,
      uuid: "",
      appId: "2036675646",
      // doctorUnionId: "00ec8279761b4ea78697c182db4e7877",
      unionName: ""
    };
  },
  computed: {},
  mounted() {
    localStorage.currentTitle = '悟糖实时血糖管理平台'
    document.title = '悟糖实时血糖管理平台'
  },
  methods: {
    // 获取滑块验证码
    getCaptcha() {
      let _this = this;
      _this.$refs["dataForm"].validate(valid => {
        if (valid) {
          //生成一个滑块验证码对象
          let captcha = new TencentCaptcha(_this.appId, function(res) {
            // 用户滑动结束或者关闭弹窗，腾讯返回的内容
            if (res.ret === 0) {
              let params = {
                randstr: res.randstr,
                ticket: res.ticket
              };
              _this
                .$http({
                  url: _this.$http.adornUrl("/bind/describeCaptchaResult"),
                  method: "post",
                  data: Qs.stringify(params)
                })
                .then(({ data }) => {
                  let captchaCode = JSON.parse(data.data).CaptchaCode;
                  if (data.data && captchaCode === 1) {
                    _this.dataFormSubmit();
                  } else {
                    _this.$message.error("滑块校验未通过，请重新尝试");
                  }
                });
            } else {
              // 提示用户完成验证
            }
          });
          // 滑块显示
          captcha.show();
        }
      });
    },
    // 提交表单
    dataFormSubmit() {
      // 判断复选框是否被勾选; 勾选则调用配置Cookie方法
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let param = {
            account: this.dataForm.account,
            password: MD5(this.dataForm.password),
            // code: this.dataForm.code,
            // uuid: this.uuid,
            roleType: 1,
            unionId: this.doctorUnionId
          };
					localStorage.removeItem('token')
          this.$http({
            url: this.$http.adornUrl("/organBusiness/login"),
            method: "post",
            data: Qs.stringify(param)
          }).then(({ data }) => {
            if (data.status) {
              localStorage.userInfo = JSON.stringify(data.data.user);
              localStorage.dept = JSON.stringify(data.data.dept);
              localStorage.setItem("token", data.data.token);
              this.$cookie.set('hospitalId',data.data.user.deptPid)
              
							this.getGzsInfo()
              
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
		// 获取团队下的病种
		getGzsInfo() {
		  let that = this;
		  that
		    .$http({
		      url: that.$http.adornUrl("/organBusiness/info"),
		      method: "get"
		    })
		    .then(({ data }) => {
		      if (data.data) {
		        if (data.data.teamsManages && data.data.teamsManages.length) {
		          that.teamsManages = data.data.teamsManages;
		          localStorage.teamsManages = JSON.stringify(that.teamsManages);
		          if (localStorage.currentTeam) {
		            that.currentTeam = JSON.parse(localStorage.currentTeam);
		          } else {
		            that.currentTeam = data.data.teamsManages[0];
		            localStorage.currentTeam = JSON.stringify(data.data.teamsManages[0]);
		          }
		          this.showTeam = true
				  this.gzsFlag = true
				  
				   localStorage.gzsFlag= true
					  this.$router.push({ name: "home" });
		        }else if(data.data.roleId=='e89325119fa411e89e43525400557296'){
		          console.log('没工作室+管理员身份');
				 this.$router.push({ name: "home" });
		        }else if(data.data.roleId!='e89325119fa411e89e43525400557296'){
					
					localStorage.gzsFlag= false
					this.$message.error('您未加入任何工作室，无法访问')

		        }
		      }
		    });
		},
    getUserInfo() {
      let that = this;
      that
        .$http({
          url: that.$http.adornUrl("/organBusiness/info"),
          method: "get"
        })
        .then(({ data }) => {
          if (data.data) {
            if (data.data.avatar) {
              that.$store.commit("common/updateImageUrl", data.data.avatar);
            }
            if (data.data.teamsManages && data.data.teamsManages.length) {
              that.$store.commit(
                "common/updateTeamsManages",
                data.data.teamsManages
              );
              that.$store.commit(
                "common/updateCurrentTeam",
                data.data.teamsManages[0]
              );
              localStorage.teamsManages = JSON.stringify(
                data.data.teamsManages
              );
              localStorage.currentTeam = data.data.teamsManages[0];
            }
          }
        });
    }
  }
};
</script>

<style lang="scss">
.login-box {
  height: 100vh;
  background: #324057;
  .form-box-con {
    position: absolute;
    width: 480px;
    height: 280px;
    left: 50%;
    top: 50%;
    margin-top: -170px;
    margin-left: -240px;
  }
  .form-box-con-title {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }
  .data-form {
    width: 480px;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 40px 20px;
    .data-form-title {
      text-align: left;
      padding-bottom: 20px;
    }
    .submit-btn {
      width: 100%;
    }
    .code-time {
      width: 120px;
      text-align: center;
      color: #17b3a3;
    }
  }
  .back-login {
    font-size: 12px;
    color: #17b3a3;
    text-align: right;
  }
  .code-form-item {
    // display: flex;
    // align-items: center;
  }
  .el-form-item {
    position: relative;
    .login-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      display: inline-block;
      color: #999;
      font-size: 16px;
      top: 7px;
      left: 10px;
      z-index: 10;
    }
    .el-input__inner {
      padding-left: 20px;
    }
    .code-img {
      width: 90px;
      height: 36px;
      background: #ccc;
      vertical-align: middle;
      transform: scale(1.4);
      position: absolute;
      top: 0;
      right: 20px;
    }
  }
}

.role-list {
  text-align: center;
  margin-bottom: 20px;
  .el-radio__label {
    color: #fff;
  }
}
.router-group {
  overflow: hidden;
  padding-top: 10px;
  a {
    color: #17b3a3;
  }
}
.footer {
  width: 100%;
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 0;
}
</style>
